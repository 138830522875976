import {useEffect, useRef} from 'react'

import * as echarts from 'echarts'

export default function PieChart(props) {
  const pieChart = useRef()

  useEffect(() => {
    let renderInstance = echarts.getInstanceByDom(pieChart.current)
    if (!renderInstance) {
      renderInstance = echarts.init(pieChart.current)
    }

    const option = {
      color: [props.color || '#53BA02', '#EBEBEB'],
      series: [
        {
          type: 'pie',
          radius: ['70%', '100%'],
          label: {
            show: false,
          },
          data: [{value: props.percentage}, {value: 100 - props.percentage}],
        },
      ],
    }

    renderInstance.setOption(option)

    return () => {
      renderInstance.dispose()
    }
  }, [props.color, props.percentage])

  return <div ref={pieChart} style={{width: '100%', height: '100%'}} />
}
