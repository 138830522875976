export default function DescriptionBox(props) {
  return (
    <dl>
      <dt>{props.title}</dt>
      {props.comments ? (
        props.comments.map((comment, index) => {
          return <dd key={index}>{comment}</dd>
        })
      ) : (
        <></>
      )}
    </dl>
  )
}
