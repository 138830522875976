import axios from 'axios'
import {createContext} from 'react'

import generateResponseJsonFormat from './lib/generateResponseJsonFormat'

export default createContext({
  accessToken: null,

  signIn: async function (userInputDataJson) {
    try {
      const signInResult = await axios.post('/auth/sign_in', userInputDataJson)
      if (signInResult.data['isSuccess']) {
        window.sessionStorage.setItem('isAuthenticated', true)
        window.location = '/'
        return signInResult.data
      } else {
        return signInResult.data
      }
    } catch (e) {
      return generateResponseJsonFormat(false, 'SIGN_IN_ERROR')
    }
  },
  signOut: function () {
    window.sessionStorage.setItem('isAuthenticated', false)
    window.location = '/'
  },

  requestApi: async function (poolAlias, packageName, methodName, params, callback) {
    callback = callback || function () {}

    if (typeof callback !== 'function') {
      return generateResponseJsonFormat(false, 'CALLBACK_ERROR')
    }

    request.bind(this)(async (requestResult) => {
      if (requestResult.isSuccess) {
        callback(requestResult)
      } else {
        if (await this.requestAuth()) {
          request.bind(this)(callback)
        } else {
          this.signOut()
        }
      }
    })

    async function request(callback) {
      try {
        const requestResult = await axios({
          method: 'POST',
          url: `/api/${methodName}?poolAlias=${poolAlias}&packageName=${packageName}`,
          headers: {Authorization: `Bearer ${this.accessToken}`},
          data: params || {},
        }).catch(async (e) => {
          /* ERR_NETWORK_CHANGED 테스트 */
          console.log('!!! ERROR !!! ', e)
          return await axios({
            method: 'POST',
            url: `/api/${methodName}?poolAlias=${poolAlias}&packageName=${packageName}`,
            headers: {Authorization: `Bearer ${this.accessToken}`},
            data: params || {},
          })
        })

        callback(requestResult.data)
      } catch (e) {
        callback(generateResponseJsonFormat(false, 'REQUEST_ERROR'))
      }
    }
  },

  requestAuth: async function () {
    try {
      const requestResult = await axios.post('/auth')

      if (requestResult.data.isSuccess) {
        this.accessToken = requestResult.data.dataSet[0]['ACCESS_TOKEN']
      }
      return requestResult.data.isSuccess
    } catch (e) {
      console.log('AUTH ERROR !! ', e)
      return false
    }
  },
})
