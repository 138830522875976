import { useEffect, useState, useContext, useCallback } from 'react'
import globalContext from '../globalContext'

import Table from '../components/Table'

import RadarChart from '../components/RadarChart'

import MeasurementInformation from '../components/MeasurementInformation'
import PieChartBoxSet from '../components/PieChartBoxSet'

export default function Main() {
  const $ = useContext(globalContext)

  return (
    <section className="con_sec">
      <div className="ma_flex_wrap inFull">
        <SolarPart />
        <HeatingPart />
      </div>
    </section>
  )
}

function SolarPart() {
  const $ = useContext(globalContext)

  const [solarSites, setSolarSites] = useState([])
  const [selectedSolarSiteRow, setSelectedSolarSiteRow] = useState(0)

  const [solarStatusPower, setSolarStatusPower] = useState({
    CURRENT_EFFICIENCY: 0,
    POW_AC_P: 0,
    POW_AC_P_UNIT: 'kW',
    SITE_CAPACITY: 0,
    YESTERDAY_HOUR: 0,
    TODAY_HOUR: 0,
    DEFAULT_HOUR: 0,
    POW_YESTERDAY: 0,
    POW_YESTERDAY_UNIT: 'kWh',
    POW_TODAY: 0,
    POW_TODAY_UNIT: 'kWh',
    TODAY_CAPACITY: 0,
    TODAY_CAPACITY_UNIT: 'kWh',
    POW_LASTMONTH: 0,
    POW_LASTMONTH_UNIT: 'kWh',
    POW_THISMONTH: 0,
    POW_THISMONTH_UNIT: 'kWh',
    MONTH_CAPACITY: 0,
    MONTH_CAPACITY_UNIT: 'kWh',
    POW_THISYEAR: 0,
    POW_THISYEAR_UNIT: 'kWh',
    YEAR_CAPACITY: 0,
    YEAR_CAPACITY_UNIT: 'kWh',
    CO2: 0,
    POW_TOTAL: 0,
    POW_TOTAL_UNIT: 'kWh',
  })
  //OPEN_SITE_STATUS
  useEffect(() => {
    $.requestApi('KOPTI', 'KOPTI', 'OPEN_SITE_STATUS', {}, (requestResult) => {
      if (requestResult['isSuccess']) {
        setSolarSites(requestResult.dataSet)
      } else {
        console.log('isSuccess fail11111111111')
      }
    })
  }, [])

  useEffect(() => {
    const requestStatusPower = () => {
      if (selectedSolarSiteRow) {
        $.requestApi('KOPTI', 'KOPTI', 'OPEN_STATUS_POWER', { SITE_ID: selectedSolarSiteRow['SITE_ID'] }, (requestResult) => {
          //SITE_ID: selectedSolarSiteRow['SITE_ID'] 프로시져 COPY 버전 추가 하였고, 내부에 아무것도(간단한 쿼리) 입력안했음에도.. isSuccess fail 발생했네요..
          console.log('requestResult : ' + requestResult)
          if (requestResult['isSuccess']) {
            const requestResultData = requestResult.dataSet[0] || {}

            if (solarStatusPower['POW_TOTAL'] !== requestResultData['POW_TOTAL'] || solarStatusPower['POW_AC_P'] !== requestResultData['POW_AC_P']) {
              setSolarStatusPower(requestResultData)
            }
          } else {
            console.log('isSuccess fail')
          }
        })
      }
    }

    requestStatusPower()
    const request = setInterval(async () => {
      try {
        await requestStatusPower()
      } catch (e) {
        console.log(e)
      }
    }, 3000)

    return () => {
      return clearInterval(request)
    }
  }, [selectedSolarSiteRow['SITE_ID'], solarStatusPower])
  console.log(solarStatusPower)
  console.log('selectedSolarSiteRow[SITE_ID] : ' + selectedSolarSiteRow['SITE_ID'])
  return (
    <div className="top">
      <div className="lb">
        <p className="tit icon">태양광</p>
        <div className="con">
          <Table
            columns={[
              { label: '부지 ID', columnName: 'SITE_ID' },
              { label: '장소 명', columnName: 'SITE_NAME' },
              { label: '설치장소', columnName: 'SITE_ADDRESS' },
              { label: '발전상태', columnName: 'SITE_STATUS' },
            ]}
            rows={solarSites}
            onClickTargetRow={setSelectedSolarSiteRow}
          />
        </div>
      </div>
      <div className="rb">
        <div className="cir01_g_wrapper">
          {/* <div id="chartdiv" style={{width: '100%', height: '100%', minHeight: '285px'}}></div> */}

          <RadarChart data={solarStatusPower} />
        </div>
        <div className="cir02_g_wrapper">
          <PieChartBoxSet data={solarStatusPower} />
        </div>
      </div>
    </div>
  )
}

function HeatingPart(props) {
  const $ = useContext(globalContext)

  const [heatingSites, setHeatingSites] = useState([])
  const [selectedHeatingSiteRow, setSelectedHeatingSiteRow] = useState({})
  const [heatingLatestStatus, setHeatingLatestStatus] = useState({})

  useEffect(() => {
    $.requestApi('HEATING', 'HEATING', 'OPEN_SITE_LIST', {}, (requestResult) => {
      if (requestResult['isSuccess']) {
        setHeatingSites(requestResult.dataSet)
      }
    })
  }, [])

  useEffect(() => {
    if (selectedHeatingSiteRow['SITE_ID']) {
      const requestHeatingLatestStatus = () => {
        $.requestApi('HEATING', 'HEATING', 'OPEN_HEATING_LATEST_STATUS', { SITE_ID: selectedHeatingSiteRow['SITE_ID'] }, (requestResult) => {
          if (requestResult['isSuccess']) {
            const requestResultData = requestResult.dataSet[0] || {}
            if (heatingLatestStatus['SITE_ID'] !== requestResultData['SITE_ID'] || heatingLatestStatus['HEATING_DATETIME'] !== requestResultData['HEATING_DATETIME']) {
              setHeatingLatestStatus(requestResultData)
            }
          }
        })
      }

      requestHeatingLatestStatus()

      const request = setInterval(() => {
        try {
          requestHeatingLatestStatus()
        } catch (e) {
          console.log(e)
        }
      }, 3000)

      return () => {
        return clearInterval(request)
      }
    }
  }, [selectedHeatingSiteRow['SITE_ID'], heatingLatestStatus])

  return (
    <div className="bott">
      <div className="lb">
        <p className="tit icon">태양열</p>

        <div className="con">
          <Table
            columns={[
              { label: '부지 ID', columnName: 'SITE_ID' },
              { label: '장소 명', columnName: 'SITE_NAME' },
              { label: '설치장소', columnName: 'SITE_ADDRESS' },
            ]}
            rows={heatingSites}
            onClickTargetRow={(targetRow) => {
              if (targetRow['SITE_ID'] !== selectedHeatingSiteRow['SITE_ID']) {
                setSelectedHeatingSiteRow(targetRow)
              }
            }}
          />
        </div>
      </div>

      <div className="cb">
        <p className="tit">실시간 계측정보</p>
        <MeasurementInformation data={heatingLatestStatus} />
      </div>

      <div className="rb">
        <p className="tit">
          결함 <span>Fault</span>
        </p>

        <div className="con">
          <Table
            columns={[
              { label: '발생시간', width: '26.66%', columnName: 'occurrenceTime' },
              { label: '결함종류', width: '26.66%', columnName: 'defectType' },
              { label: '결함위치', width: '26.66%', columnName: 'defectLocation' },
              { label: '상태', width: '20%', columnName: 'state' },
            ]}
            rows={[
              {
                occurrenceTime: '16:49:42',
                defectType: 'failure',
                defectLocation: '한천면(열)',
                state: '해제',
              },
              {
                occurrenceTime: '16:49:42',
                defectType: 'failure',
                defectLocation: '한천면(열)',
                state: '해제',
              },
            ]}
          />
        </div>
      </div>
    </div>
  )
}
