import {useRef, useEffect} from 'react'

import * as echarts from 'echarts'

export default function LineChart(props) {
  const chart = useRef()

  useEffect(() => {
    const {xAxis, series, legend} = props.data
    let renderInstance = echarts.getInstanceByDom(chart.current)

    if (!renderInstance) {
      renderInstance = echarts.init(chart.current)
    }

    if (props.isChartLoading) {
      renderInstance.showLoading()
    } else {
      renderInstance.hideLoading()
    }

    const option = {
      tooltip: {
        trigger: 'axis',
      },
      legend: {
        data: legend,
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: xAxis,
      },
      yAxis: {
        type: 'value',
      },
      series: series,
    }

    renderInstance.setOption(option)
    return () => {
      renderInstance.clear()
      renderInstance.dispose()
    }
  }, [props])

  return <div ref={chart} id="chartLine" style={{height: '100%'}}></div>
}
