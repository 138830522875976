import { useState } from 'react'
import { useNavigate } from 'react-router'

export default function Nav(props) {
  const navigate = useNavigate()
  const [menuName, setmenuName] = useState('main')

  return (
    <nav className={props.mobileMenuClassName}>
      <ul id="gnb">
        <li className={menuName === 'main' ? 'on' : ''}>
          <a
            onClick={() => {
              navigate('/index', { replace: true })
              setmenuName('main')

              props.setIsMobileMenuOpen(false)
              props.setMobileMenuClassName('')
            }}
          >
            <span></span> <b>Main</b>
          </a>
        </li>
        <li className={menuName === 'trend' ? 'on' : ''}>
          <a
            onClick={() => {
              navigate('/trend', { replace: true })
              setmenuName('trend')

              props.setIsMobileMenuOpen(false)
              props.setMobileMenuClassName('')
            }}
          >
            <span></span> <b>Trend</b>
          </a>
        </li>
        {/* <li className={menuName === 'report' ? 'on' : ''}>
          <a
            onClick={() => {
              navigate('/report', {replace: true})
              setmenuName('report')

              props.setIsMobileMenuOpen(false)
              props.setMobileMenuClassName('')
            }}
          >
            <span></span> <b>안전관리메뉴얼</b>
          </a>
        </li> */}
        <li className={menuName === 'manual' ? 'on' : ''}>
          <a
            onClick={() => {
              window.open('https://ecms.tef.ai/bbs/login.php')
            }}
          >
            <span></span> <b>Manual</b>
          </a>
        </li>
        {/* <li className={menuName === 'setting' ? 'on' : ''}>
          <a
            onClick={() => {
              navigate('/setting', {replace: true})
              setmenuName('setting')

              props.setIsMobileMenuOpen(false)
              props.setMobileMenuClassName('')
            }}
          >
            <span></span> <b>Setting</b>
          </a>
        </li> */}
      </ul>
    </nav>
  )
}
