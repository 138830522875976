import {useContext, useState} from 'react'
import {Box, Grid, Typography, TextField, Button, Container} from '@material-ui/core'

import globalContext from '../globalContext'

export default function SignIn() {
  const $ = useContext(globalContext)

  const [userId, setUserId] = useState('')
  const [userPassword, setUserPassword] = useState('')

  const [isUserIdInputError, setIsUserIdInputError] = useState(false)
  const [isUserPasswordInputError, setIsUserPasswordInputError] = useState(false)

  const [userIdInputHelperText, setUserIdInputHelperText] = useState('')
  const [userPasswordInputHelperText, setUserPasswordInputHelperText] = useState('')

  const userIdChangeHandle = (e) => {
    setUserId(e.target.value)
  }
  const userPasswordChangeHandle = (e) => {
    setUserPassword(e.target.value)
  }

  const keyDownHandle = (e) => {
    if (e.keyCode === 13) {
      signInClickHandle()
    }
  }

  const userIdValidateHandle = (e) => {
    if (!userId.length) {
      setIsUserIdInputError(true)
      setUserIdInputHelperText('id is required')
    } else {
      setIsUserIdInputError(false)
      setUserIdInputHelperText('')
    }
  }
  const userPasswordValidateHandle = (e) => {
    if (!userPassword.length) {
      setIsUserPasswordInputError(true)
      setUserPasswordInputHelperText('password is required')
    } else {
      setIsUserPasswordInputError(false)
      setUserPasswordInputHelperText('')
    }
  }

  const signInClickHandle = async () => {
    if (!(userId && userPassword)) {
      alert('아이디와 비밀번호를 입력해 주세요')
      return
    }

    const signInResult = await $.signIn({
      USER_ID: userId,
      USER_PASSWORD: userPassword,
    })

    if (!signInResult.isSuccess) {
      setUserId('')
      setUserPassword('')
      alert('로그인 실패')
    }
  }

  return (
    <Container maxWidth="xs" style={{marginTop: '35vh'}}>
      <Grid container style={{rowGap: '5px'}} direction="column">
        <Box>
          <Typography variant="h4">Sign In</Typography>
          <Typography variant="h6" color="textSecondary">
            Korea Photonics Technology Institute
          </Typography>
        </Box>
        <TextField
          required
          label="id"
          variant="outlined"
          fullWidth
          size="small"
          value={userId}
          autoFocus
          onChange={userIdChangeHandle}
          onKeyDown={keyDownHandle}
          onBlur={userIdValidateHandle}
          error={isUserIdInputError}
          helperText={userIdInputHelperText}
        />
        <TextField
          required
          label="password"
          type="password"
          variant="outlined"
          size="small"
          fullWidth
          value={userPassword}
          onChange={userPasswordChangeHandle}
          onKeyDown={keyDownHandle}
          onBlur={userPasswordValidateHandle}
          error={isUserPasswordInputError}
          helperText={userPasswordInputHelperText}
        />
        <Button variant="contained" color="primary" fullWidth size="large" onClick={signInClickHandle}>
          SIGN IN
        </Button>
      </Grid>
    </Container>
  )
}
