import {useRef, useEffect} from 'react'

import * as echarts from 'echarts'

export default function BarChart(props) {
  const {xAxis, series} = props.data

  const chart = useRef(null)

  useEffect(() => {
    let renderInstance = echarts.getInstanceByDom(chart.current)

    if (!renderInstance) {
      renderInstance = echarts.init(chart.current)
    }

    if (props.isChartLoading) {
      renderInstance.showLoading()
    } else {
      renderInstance.hideLoading()
    }

    const option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      legend: {},
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
      xAxis: [
        {
          type: 'category',
          data: xAxis,
        },
      ],
      yAxis: [
        {
          type: 'value',
        },
      ],

      series: series,
    }

    renderInstance.setOption(option)

    return () => {
      renderInstance.clear()
      renderInstance.dispose()
    }
  }, [xAxis, series, props.isChartLoading])

  return <div ref={chart} id="graphLine" style={{height: '100%'}}></div>
}
