import {useState, useContext, useEffect} from 'react'

import moment from 'moment'
import datepickerGlue from '@date-io/moment'
import {MuiPickersUtilsProvider, DatePicker} from '@material-ui/pickers'

import globalContext from '../globalContext'
import Table from '../components/Table'
import BarChart from '../components/BarChart'
import LineChart from '../components/LineChart'

export default function Trend() {
  const [selectedSolarSiteRow, setSelectedSolarSiteRow] = useState(0)
  const [selectedHeatingSiteRow, setSelectedHeatingSiteRow] = useState(0)

  const [selectedTabName, setSelectedTabName] = useState('SOLAR')

  return (
    <>
      <section className="con_sec">
        <div className="trend_top">
          <TableTabPart
            setSelectedSolarSiteRow={setSelectedSolarSiteRow}
            setSelectedHeatingSiteRow={setSelectedHeatingSiteRow}
            setSelectedTabName={setSelectedTabName}
          />
          <WeatherPart />
        </div>

        <p className="tit">통계 그래프</p>
        <ChartPart
          selectedTabName={selectedTabName}
          selectedSolarSiteRow={selectedSolarSiteRow}
          selectedHeatingSiteRow={selectedHeatingSiteRow}
        />
      </section>
    </>
  )
}

function TableTabPart(props) {
  const $ = useContext(globalContext)

  const [isFirstTabSelected, setIsFirstTabSelected] = useState(true)
  const [solarSiteTrend, setSolarSiteTrend] = useState([])
  const [heatingSites, setHeatingSites] = useState([])

  useEffect(() => {
    if (isFirstTabSelected) {
      const requestSiteTrend = () => {
        $.requestApi('KOPTI', 'KOPTI', 'OPEN_SITE_TREND', {}, (requestResult) => {
          if (requestResult['isSuccess']) {
            const requestResultData = requestResult.dataSet
            setSolarSiteTrend(requestResultData)
          }
        })
      }

      requestSiteTrend()
      const request = setInterval(async () => {
        try {
          await requestSiteTrend()
        } catch (e) {
          console.log(e)
        }
      }, 60000)

      return () => {
        return clearInterval(request)
      }
    }
  }, [isFirstTabSelected])

  useEffect(() => {
    if (!isFirstTabSelected) {
      $.requestApi('HEATING', 'HEATING', 'OPEN_SITE_LIST', {}, (requestResult) => {
        if (requestResult['isSuccess']) {
          setHeatingSites(requestResult.dataSet)
        }
      })
    }
  }, [isFirstTabSelected])

  return (
    <div className="lb">
      <ul className="tab_01 tab_solar">
        <li
          className={isFirstTabSelected ? 'active' : ''}
          onClick={() => {
            if (!isFirstTabSelected) {
              setIsFirstTabSelected(true)
              props.setSelectedTabName('SOLAR')
            }
          }}
        >
          <a>태양광</a>
        </li>
        <li
          className={!isFirstTabSelected ? 'active' : ''}
          onClick={() => {
            if (isFirstTabSelected) {
              setIsFirstTabSelected(false)
              props.setSelectedTabName('HEATING')
            }
          }}
        >
          <a>태양열</a>
        </li>
      </ul>
      <div className="tab_solar_con">
        <div className="tbl_hori_wrap" style={{display: isFirstTabSelected ? '' : 'none'}}>
          <Table
            columns={[
              {label: '부지 ID', columnName: 'SITE_ID'},
              {label: '장소 명', columnName: 'SITE_NAME'},
              {label: '설치장소', columnName: 'SITE_ADDRESS'},
              {label: '현재출력', columnName: 'POW_AC_P'},
              {label: '발전효율', columnName: 'CURRENT_EFFICIENCY'},
              {label: '금일발전량', columnName: 'POW_TODAY'},
              {label: '금일발전시간', columnName: 'TODAY_HOUR'},
            ]}
            rows={solarSiteTrend}
            onClickTargetRow={(targetRow) => {
              // if (targetRow['SITE_ID'] !== props.selectedSolarSiteRow['SITE_ID']) {
              props.setSelectedSolarSiteRow(targetRow)
              // }
            }}
          />
        </div>

        <div className="tbl_hori_wrap" style={{display: !isFirstTabSelected ? '' : 'none'}}>
          <Table
            columns={[
              {label: '부지 ID', columnName: 'SITE_ID'},
              {label: '장소 명', columnName: 'SITE_NAME'},
              {label: '설치장소', columnName: 'SITE_ADDRESS'},
            ]}
            rows={heatingSites}
            onClickTargetRow={(targetRow) => {
              // if (targetRow['SITE_ID'] !== props.selectedSolarSiteRow['SITE_ID']) {
              props.setSelectedHeatingSiteRow(targetRow)
              // }
            }}
          />
        </div>
      </div>
    </div>
  )
}

function WeatherPart(props) {
  return (
    <div className="rb">
      <p className="tit">날씨정보(광주광역시 승촌동)</p>

      <div className="inner_weather_box">
        <div className="ro-b">
          <div className="lb">
            <h3>시간대별 날씨</h3>
            <ul className="cate_w1">
              <li className="active">
                <a>
                  <span className="icon"></span>
                  <span className="txt">날씨</span>
                </a>
              </li>
              <li>
                <a>
                  <span className="icon"></span>
                  <span className="txt">강수</span>
                </a>
              </li>
              <li>
                <a>
                  <span className="icon"></span>
                  <span className="txt">바람</span>
                </a>
              </li>
              <li>
                <a>
                  <span className="icon"></span>
                  <span className="txt">습도</span>
                </a>
              </li>
            </ul>
          </div>
          <div className="rb">
            <div className="inner_weather_sub1_w">
              <div className="co-b">
                <p className="num">30º</p>
                <div className="icon_b">
                  <img src="images/icon_weather_sun.jpg" alt="맑음 아이콘" />
                </div>
                <div className="txt on">10시</div>
              </div>
              <div className="co-b">
                <p className="num">30º</p>
                <div className="icon_b">
                  <img src="images/icon_weather_sun.jpg" alt="맑음 아이콘" />
                </div>
                <div className="txt green">11시</div>
              </div>
              <div className="co-b">
                <p className="num">32º</p>
                <div className="icon_b">
                  <img src="images/icon_weather_sun.jpg" alt="맑음 아이콘" />
                </div>
                <div className="txt green">12시</div>
              </div>
              <div className="co-b">
                <p className="num">32º</p>
                <div className="icon_b">
                  <img src="images/icon_weather_sun.jpg" alt="맑음 아이콘" />
                </div>
                <div className="txt green">13시</div>
              </div>
              <div className="co-b">
                <p className="num">32º</p>
                <div className="icon_b">
                  <img src="images/icon_weather_cloud.jpg" alt="구름 아이콘" />
                </div>
                <div className="txt green">14시</div>
              </div>
              <div className="co-b">
                <p className="num">32º</p>
                <div className="icon_b">
                  <img src="images/icon_weather_sun.jpg" alt="맑음 아이콘" />
                </div>
                <div className="txt">15시</div>
              </div>
              <div className="co-b">
                <p className="num">30º</p>
                <div className="icon_b">
                  <img src="images/icon_weather_sun.jpg" alt="맑음 아이콘" />
                </div>
                <div className="txt">18시</div>
              </div>
              <div className="co-b">
                <p className="num">26º</p>
                <div className="icon_b">
                  <img src="images/icon_weather_night.jpg" alt="밤 아이콘" />
                </div>
                <div className="txt">21시</div>
              </div>
            </div>
          </div>
        </div>
        <div className="ro-b">
          <div className="lb">
            <h3>주간날씨</h3>
            <div className="page-w-sm">
              <button className="btn-p prev" title="이전">
                <img src="images/icon_weater_prev_arrow.png" alt="" />
              </button>
              <span className="pageNum">
                <b>1</b> / 2
              </span>
              <button className="btn-p prev" title="다음">
                <img src="images/icon_weater_next_arrow.png" alt="" />
              </button>
            </div>
          </div>
          <div className="rb">
            <div className="inner_weather_sub2_w">
              <div className="co-b">
                <div className="date">
                  <span className="day">화</span>
                  <span className="num on">오늘</span>
                </div>
                <ul className="wea_ul">
                  <li>
                    <span className="icon">
                      <img src="images/icon_weather_sun.jpg" alt="맑음날씨" />
                    </span>
                    <span className="txt">0%</span>
                  </li>
                  <li>
                    <span className="icon">
                      <img src="images/icon_weather_sun.jpg" alt="맑음날씨" />
                    </span>
                    <span className="txt">0%</span>
                  </li>
                </ul>
                <div className="degree">18º / 34º</div>
              </div>
              <div className="co-b">
                <div className="date">
                  <span className="day">수</span>
                  <span className="num">내일</span>
                </div>
                <ul className="wea_ul">
                  <li>
                    <span className="icon">
                      <img src="images/icon_weather_sun.jpg" alt="맑음날씨" />
                    </span>
                    <span className="txt">0%</span>
                  </li>
                  <li>
                    <span className="icon">
                      <img src="images/icon_weather_cloud.jpg" alt="구름날씨" />
                    </span>
                    <span className="txt">30%</span>
                  </li>
                </ul>
                <div className="degree">20º / 32º</div>
              </div>
              <div className="co-b">
                <div className="date">
                  <span className="day">목</span>
                  <span className="num">모래</span>
                </div>
                <ul className="wea_ul">
                  <li>
                    <span className="icon">
                      <img src="images/icon_weather_rainy.jpg" alt="비오는날씨" />
                    </span>
                    <span className="txt blue">60%</span>
                  </li>
                  <li>
                    <span className="icon">
                      <img src="images/icon_weather_cloud.jpg" alt="구름날씨" />
                    </span>
                    <span className="txt">30%</span>
                  </li>
                </ul>
                <div className="degree">22º / 30º</div>
              </div>
              <div className="co-b">
                <div className="date">
                  <span className="day">금</span>
                  <span className="num">6.12.</span>
                </div>
                <ul className="wea_ul">
                  <li>
                    <span className="icon">
                      <img src="images/icon_weather_cloud2.jpg" alt="구름날씨" />
                    </span>
                    <span className="txt">30%</span>
                  </li>
                  <li>
                    <span className="icon">
                      <img src="images/icon_weather_rainy.jpg" alt="비오는날씨" />
                    </span>
                    <span className="txt blue">60%</span>
                  </li>
                </ul>
                <div className="degree">21º / 31º</div>
              </div>
              <div className="co-b">
                <div className="date">
                  <span className="day">토</span>
                  <span className="num">6.13.</span>
                </div>
                <ul className="wea_ul">
                  <li>
                    <span className="icon">
                      <img src="images/icon_weather_rainy.jpg" alt="비오는날씨" />
                    </span>
                    <span className="txt blue">60%</span>
                  </li>
                  <li>
                    <span className="icon">
                      <img src="images/icon_weather_rainy.jpg" alt="비오는날씨" />
                    </span>
                    <span className="txt blue">60%</span>
                  </li>
                </ul>
                <div className="degree">22º / 26º</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function ChartPart(props) {
  const [statisticsDate, setStatisticsDate] = useState(moment(new Date()).format('YYYYMMDD'))
  const [selectedSatisticsType, setSelectedSatisticsType] = useState('TREND')

  return (
    <div className="solar_graph_wrap">
      <ul className="tab_02">
        <MuiPickersUtilsProvider utils={datepickerGlue}>
          <DatePicker
            style={{paddingRight: '10px'}}
            value={statisticsDate}
            onChange={(selectedDate) => {
              const convertedTrendDate = moment(selectedDate).format('YYYYMMDD')
              if (statisticsDate !== convertedTrendDate) {
                setStatisticsDate(moment(selectedDate).format('YYYYMMDD'))
              }
            }}
            autoOk
            disableFuture
            format={'YYYY-MM-DD (dddd)'}
          />
        </MuiPickersUtilsProvider>
        <li className={selectedSatisticsType === 'TREND' ? 'active' : ''}>
          <a
            onClick={() => {
              setSelectedSatisticsType('TREND')
            }}
          >
            트렌드
          </a>
        </li>
        {props.selectedTabName === 'SOLAR' ? (
          <>
            <li className={selectedSatisticsType === 'DAILY' ? 'active' : ''}>
              <a
                onClick={() => {
                  setSelectedSatisticsType('DAILY')
                }}
              >
                일
              </a>
            </li>
            <li className={selectedSatisticsType === 'MONTHLY' ? 'active' : ''}>
              <a
                onClick={() => {
                  setSelectedSatisticsType('MONTHLY')
                }}
              >
                월
              </a>
            </li>
            <li className={selectedSatisticsType === 'YEARLY' ? 'active' : ''}>
              <a
                onClick={() => {
                  setSelectedSatisticsType('YEARLY')
                }}
              >
                년
              </a>
            </li>
          </>
        ) : (
          <>
            <li style={{display: 'none'}} className={selectedSatisticsType === 'DAILY' ? 'active' : ''}>
              <a
                onClick={() => {
                  setSelectedSatisticsType('DAILY')
                }}
              >
                일
              </a>
            </li>
            <li style={{display: 'none'}} className={selectedSatisticsType === 'MONTHLY' ? 'active' : ''}>
              <a
                onClick={() => {
                  setSelectedSatisticsType('MONTHLY')
                }}
              >
                월
              </a>
            </li>
            <li style={{display: 'none'}} className={selectedSatisticsType === 'YEARLY' ? 'active' : ''}>
              <a
                onClick={() => {
                  setSelectedSatisticsType('YEARLY')
                }}
              >
                년
              </a>
            </li>
          </>
        )}
      </ul>

      {props.selectedTabName === 'SOLAR' ? (
        <p className="x-sbj">
          발전량 <span>kWh</span>
        </p>
      ) : (
        <p className="x-sbj">
          온도 <span>°C</span>
        </p>
      )}

      <div className="grpah_box">
        {props.selectedTabName === 'SOLAR' ? (
          <SolarChartWrapper
            selectedSatisticsType={selectedSatisticsType}
            selectedSiteId={props.selectedSolarSiteRow['SITE_ID']}
            statisticsDate={statisticsDate}
          />
        ) : (
          <HeatingChartWrapper
            selectedSatisticsType={selectedSatisticsType}
            selectedSiteId={props.selectedHeatingSiteRow['SITE_ID']}
            statisticsDate={statisticsDate}
          />
        )}
      </div>
    </div>
  )
}

function SolarChartWrapper(props) {
  const $ = useContext(globalContext)

  const [chartData, setChartData] = useState({xAxis: [], series: []})
  const [isChartLoading, setIsChartLoading] = useState(false)

  useEffect(() => {
    if (props.selectedSiteId) {
      setChartData({})
      setIsChartLoading(true)
      const requestStatistics = () => {
        $.requestApi(
          'KOPTI',
          'KOPTI',
          `OPEN_POWER_${props.selectedSatisticsType}_STATISTICS`,
          {
            SITE_ID: props.selectedSiteId,
            POW_DATE: props.statisticsDate,
          },
          (requestResult) => {
            if (requestResult['isSuccess']) {
              const statisticsTypeMap = {
                TREND: 'POW_TIME',
                DAILY: 'POW_HOUR',
                MONTHLY: 'POW_DAY',
                YEARLY: 'POW_MONTH',
              }

              setChartData(
                ((selectedSatisticsType, dataSet) => {
                  let xAxis = []
                  let series = []

                  xAxis = Object.keys(grouping(dataSet, selectedSatisticsType))
                  const powIdGroupingData = grouping(dataSet, 'POW_ID')

                  series = Object.keys(powIdGroupingData).map((powId, index) => {
                    return {
                      name: `INV#${powId}`,
                      type: 'bar',
                      stack: 'stackedByPowTime',
                      data: powIdGroupingData[powId].map((element) => {
                        return element['POW_QUANTITY']
                      }),
                    }
                  })
                  if (series.length) {
                    series[series.length - 1]['label'] = {
                      show: true,
                      fontSize: 10,
                      // color: 'black',
                      position: 'top',
                      formatter: (params) => {
                        return series.reduce((accumulatedValue, currentElement) => {
                          return accumulatedValue + currentElement['data'][params['dataIndex']]
                        }, 0)
                      },
                    }
                  }
                  return {xAxis: xAxis, series: series}

                  function grouping(targetArray, groupingKeyName) {
                    return targetArray.reduce((accumulatedElement, currentElement) => {
                      const groupName = currentElement[groupingKeyName]

                      if (accumulatedElement[groupName] === undefined) {
                        accumulatedElement[groupName] = []
                      }

                      accumulatedElement[groupName].push(currentElement)
                      return accumulatedElement
                    }, {})
                  }
                })(statisticsTypeMap[props.selectedSatisticsType], requestResult.dataSet),
              )
              setIsChartLoading(false)
            }
          },
        )
      }

      requestStatistics()
      const request = setInterval(async () => {
        try {
          await requestStatistics()
        } catch (e) {
          console.log(e)
        }
      }, 600000)
      // }, 3000)

      return () => {
        return clearInterval(request)
      }
    }
  }, [props.selectedSiteId, props.statisticsDate, props.selectedSatisticsType])

  return <BarChart data={chartData} isChartLoading={isChartLoading} />
}

function HeatingChartWrapper(props) {
  const $ = useContext(globalContext)

  const [chartData, setChartData] = useState({xAxis: [], series: [], legend: []})
  const [isChartLoading, setIsChartLoading] = useState(false)

  useEffect(() => {
    if (props.selectedSiteId) {
      setChartData({})
      setIsChartLoading(true)

      const requestStatistics = () => {
        $.requestApi(
          'HEATING',
          'HEATING',
          `OPEN_HEATING_${props.selectedSatisticsType}_STATISTICS`,
          {
            SITE_ID: props.selectedSiteId,
            HEATING_DATE: props.statisticsDate,
          },
          (requestResult) => {
            if (requestResult['isSuccess']) {
              const statisticsTypeMap = {
                TREND: 'HEATING_TIME',
                DAILY: 'POW_HOUR',
                MONTHLY: 'POW_DAY',
                YEARLY: 'POW_MONTH',
              }

              setChartData(
                ((selectedSatisticsType, dataSet) => {
                  const xAxis = Object.keys(grouping(dataSet, statisticsTypeMap[selectedSatisticsType]))
                  const series = []
                  const legend = []

                  const columnInfoObjects = [
                    {columnName: 'COLD_PIPE_TEMP', desc: '급수 배관온도'},
                    {columnName: 'HOT_PIPE_TEMP', desc: '급탕 배관온도'},
                    // {columnName: 'CONSUME_FLOW_RATE', desc: '소비유량값'},
                    // {columnName: 'ACC_USE_CAL', desc: '누적사용열량'},
                    {columnName: 'COLLECTOR_IN_TEMP', desc: '집열기 입구온도'},
                    {columnName: 'COLLECTOR_OUT_TEMP', desc: '집열기 출구온도'},
                    {columnName: 'TOP_HEAT_ACCUMULATOR_TEMP', desc: '축열조 상부온도'},
                    {columnName: 'BOTTOM_HEAT_ACCUMULATOR_TEMP', desc: '축열조 하부온도'},
                    // {columnName: 'FLOW_RATE', desc: '유량값'},
                    // {columnName: 'ACC_PRODUCTION_RATE', desc: '누적 생산량'},
                    // {columnName: 'ERROR_STATUS', desc: '고장상태'},
                  ]

                  const heatingTimeGroupingData = grouping(requestResult.dataSet, 'HEATING_TIME')
                  for (const columnInfoObject of columnInfoObjects) {
                    const data = []
                    for (const heatingTime of Object.keys(heatingTimeGroupingData)) {
                      data.push(heatingTimeGroupingData[heatingTime][0][columnInfoObject['columnName']])
                    }

                    series.push({
                      name: columnInfoObject['desc'],
                      type: 'line',
                      data: data,
                    })

                    legend.push(columnInfoObject['desc'])
                  }

                  return {xAxis: xAxis, series: series, legend: legend}
                })(props.selectedSatisticsType, requestResult.dataSet),
              )

              setIsChartLoading(false)

              function grouping(targetArray, groupingKeyName) {
                return targetArray.reduce((accumulatedElement, currentElement) => {
                  const groupName = currentElement[groupingKeyName]

                  if (accumulatedElement[groupName] === undefined) {
                    accumulatedElement[groupName] = []
                  }

                  accumulatedElement[groupName].push(currentElement)
                  return accumulatedElement
                }, {})
              }
            }
          },
        )
      }

      requestStatistics()
      const request = setInterval(async () => {
        try {
          await requestStatistics()
        } catch (e) {
          console.log(e)
        }
      }, 600000)

      return () => {
        return clearInterval(request)
      }
    }
  }, [props.selectedSiteId, props.statisticsDate, props.selectedSatisticsType])

  return <LineChart isChartLoading={isChartLoading} data={chartData} />
}
