import {useContext, useState, useEffect} from 'react'
import {useNavigate} from 'react-router'

import moment from 'moment'

import globalContext from '../../globalContext'

export default function Header(props) {
  const $ = useContext(globalContext)

  const [yymmddDate, setYymmddDate] = useState(moment().format('YY.MM.DD'))
  const [hhmmTime, setHhmmTime] = useState(moment().format('HH:mm'))
  const navigate = useNavigate()

  useEffect(() => {
    setInterval(() => {
      setYymmddDate(moment().format('YY.MM.DD'))
      setHhmmTime(moment().format('HH:mm'))
    }, 1000)
  }, [])

  return (
    <header>
      <h1>
        <a
          onClick={() => {
            navigate('/index', {replace: true})
          }}
        >
          <img src="images/logo.png" alt="KOPTI 한국관기술원" /> 태양광/태양열 통합 모니터링 시스템
        </a>
      </h1>
      <div className="date_b">
        <span className="date" id="da">
          {yymmddDate}
        </span>
        <span className="time" id="ti">
          {hhmmTime}
        </span>
      </div>

      <a
        id="sign_out"
        onClick={() => {
          $.signOut()
        }}
      >
        Sign Out
      </a>

      <a
        onClick={() => {
          props.setIsMobileMenuOpen(!props.isMobileMenuOpen)
          props.setMobileMenuClassName(
            props.isMobileMenuOpen ? '' : 'on',
          ) /* 윗 줄에서 !연산자로 값을 반전 시켜 useState를 사용해도, 캡처링 된 상태라 값이 곧바로 바뀌지않기 때문에 3항 연산자 조건을 반대로 줌 */
        }}
        className={props.isMobileMenuOpen ? 'mo_menu' : 'mo_menu on'}
      >
        <span className={props.isMobileMenuOpen ? 'open' : 'close'}>
          <i className={props.isMobileMenuOpen ? 'xi-close' : 'xi-bars'} />
        </span>
      </a>
    </header>
  )
}
