import {useRoutes, Navigate} from 'react-router-dom'

import {SignInLayout, MainLayout} from './layouts'
import {SignIn, NotFound, Main, Manual, Report, Trend, Setting} from './pages'

export default function Router() {
  const isAuthenticated = window.sessionStorage.getItem('isAuthenticated') === 'true' || false

  const isAuthenticatedTrueRoutes = [
    {
      path: '/',
      element: <MainLayout />,
      children: [
        {path: '/', element: <Navigate to="/index" replace />},
        {path: 'index', element: <Main />},
        // {path: 'manual', element: <Manual />},
        // {path: 'report', element: <Report />},
        {path: 'trend', element: <Trend />},
        // {path: 'setting', element: <Setting />},
      ],
    },

    {path: '*', element: <NotFound />},
  ]

  const isAuthenticatedFalseRoutes = [
    {
      path: '/',
      element: <SignInLayout />,
      children: [
        {path: '/', element: <Navigate to="/signIn" replace />},
        {path: '*', element: <Navigate to="/signIn" replace />},

        {path: 'signIn', element: <SignIn />},
      ],
    },
  ]

  return <>{useRoutes(isAuthenticated ? isAuthenticatedTrueRoutes : isAuthenticatedFalseRoutes)}</>
}
