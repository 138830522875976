import PieChart from './PieChart'

export default function PieChartBox(props) {
  return (
    <div className="g_b">
      <p className="txt">
        <span>
          {props.aboveValue}
          {props.aboveValueUnit}
        </span>
        <span>
          {props.belowValue}
          {props.belowValueUnit}
        </span>
      </p>
      <PieChart percentage={props.percentage} color={props.chartColor} />
    </div>
  )
}
