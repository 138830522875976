import {useNavigate} from 'react-router'
import {Container, Button} from '@material-ui/core'

export default function NotFound() {
  const navigate = useNavigate()

  return (
    <Container maxWidth="xs" style={{marginTop: '35vh'}}>
      <div style={{textAlign: 'center'}}>Page Not Found</div>
      <br />
      <div style={{textAlign: 'center'}}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            navigate('/index', {replace: true})
          }}
        >
          BACK TO MAIN PAGE
        </Button>
      </div>
    </Container>
  )
}
