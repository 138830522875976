import {useLayoutEffect} from 'react'

import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'

export default function RadarChart(props) {
  am4core.useTheme(am4themes_animated)

  useLayoutEffect(() => {
    const chart = am4core.create('chartdiv', am4charts.RadarChart)

    // Add data
    chart.data = [
      {
        category: `현재출력(${props.data['CURRENT_EFFICIENCY'] || 0}%)`,
        value: props.data['CURRENT_EFFICIENCY'] || 0,
        full: 100,
      },
      {
        category: `금일발전량(${props.data['POW_TODAY_EFFICIENCY'] || 0}%)`,
        value: props.data['POW_TODAY_EFFICIENCY'] || 0,
        full: 100,
      },
      {
        category: `금월발전량(${props.data['POW_THISMONTH_EFFICIENCY'] || 0}%)`,
        value: props.data['POW_THISMONTH_EFFICIENCY'] || 0,
        full: 100,
      },
      {
        category: `년간 발전량(${props.data['POW_THISYEAR_EFFICIENCY'] || 0}%)`,
        value: props.data['POW_THISYEAR_EFFICIENCY'] || 0,
        full: 100,
      },
    ]

    // Make chart not full circle
    chart.startAngle = -90
    chart.endAngle = 180
    chart.innerRadius = am4core.percent(20)

    // Set number format
    chart.numberFormatter.numberFormat = "#.#'%'"

    // Create axes
    var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis())
    categoryAxis.dataFields.category = 'category'
    categoryAxis.renderer.grid.template.location = 0
    categoryAxis.renderer.grid.template.strokeOpacity = 0
    categoryAxis.renderer.labels.template.horizontalCenter = 'right'
    categoryAxis.renderer.labels.template.fontWeight = 500
    categoryAxis.renderer.labels.template.fontSize = 14
    categoryAxis.renderer.labels.template.adapter.add('fill', function (fill, target) {
      return target.dataItem.index >= 0 ? chart.colors.getIndex(target.dataItem.index) : fill
    })
    categoryAxis.renderer.minGridDistance = 10

    var valueAxis = chart.xAxes.push(new am4charts.ValueAxis())
    valueAxis.renderer.grid.template.strokeOpacity = 0
    valueAxis.min = 0
    valueAxis.max = 100
    valueAxis.fontSize = 10
    valueAxis.strictMinMax = true

    // Create series
    var series1 = chart.series.push(new am4charts.RadarColumnSeries())
    series1.dataFields.valueX = 'full'
    series1.dataFields.categoryY = 'category'
    series1.clustered = false
    series1.columns.template.fill = new am4core.InterfaceColorSet().getFor('alternativeBackground')
    series1.columns.template.fillOpacity = 0.08
    series1.columns.template.cornerRadiusTopLeft = 20
    series1.columns.template.strokeWidth = 0
    series1.columns.template.radarColumn.cornerRadius = 20

    var series2 = chart.series.push(new am4charts.RadarColumnSeries())
    series2.dataFields.valueX = 'value'
    series2.dataFields.categoryY = 'category'
    series2.clustered = false
    series2.columns.template.strokeWidth = 0
    series2.columns.template.tooltipText = '{category}: [bold]{value}[/]'
    series2.columns.template.radarColumn.cornerRadius = 20

    series2.columns.template.adapter.add('fill', function (fill, target) {
      return chart.colors.getIndex(target.dataItem.index)
    })

    // Add cursor
    chart.cursor = new am4charts.RadarCursor()
  }, [props])

  return <div id="chartdiv" style={{width: '100%', height: '100%', minHeight: '285px'}}></div>
}
