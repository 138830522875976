import {memo} from 'react'

import DescriptionBox from '../components/DescriptionBox'
import PieChartBox from '../components/PieChartBox'

export default memo(
  function PieChartBoxSet(props) {
    return (
      <ul>
        <li>
          <DescriptionBox title={'현재출력'} comments={['발전효율', `${props.data['CURRENT_EFFICIENCY']}%`]} />
          <PieChartBox
            aboveValue={props.data['POW_AC_P']}
            belowValue={props.data['SITE_CAPACITY']}
            aboveValueUnit={props.data['POW_AC_P_UNIT']}
            belowValueUnit={'kW'}
            percentage={props.data['CURRENT_EFFICIENCY']}
            chartColor={'#7D65DB'}
          />
        </li>
        <li>
          <DescriptionBox title={'금일발전시간'} comments={['전일발전시간', `${props.data['YESTERDAY_HOUR']}h`]} />
          <PieChartBox
            aboveValue={props.data['TODAY_HOUR']}
            belowValue={props.data['DEFAULT_HOUR']}
            aboveValueUnit={'h'}
            belowValueUnit={'h'}
            percentage={props.data['TODAY_HOUR_EFFICIENCY']}
            chartColor={'#53BA02'}
          />
        </li>
        <li>
          <DescriptionBox
            title={'금일발전량'}
            comments={['전일발전량', `${props.data['POW_YESTERDAY']}${props.data['POW_YESTERDAY_UNIT']}`]}
          />
          <PieChartBox
            aboveValue={props.data['POW_TODAY']}
            belowValue={props.data['TODAY_CAPACITY']}
            aboveValueUnit={props.data['POW_TODAY_UNIT']}
            belowValueUnit={props.data['TODAY_CAPACITY_UNIT']}
            percentage={props.data['POW_TODAY_EFFICIENCY']}
            chartColor={'#6671DB'}
          />
        </li>
        <li>
          <DescriptionBox
            title={'금월발전량'}
            comments={['전월발전량', `${props.data['POW_LASTMONTH']}${props.data['POW_LASTMONTH_UNIT']}`]}
          />
          <PieChartBox
            aboveValue={props.data['POW_THISMONTH']}
            belowValue={props.data['MONTH_CAPACITY']}
            aboveValueUnit={props.data['POW_THISMONTH_UNIT']}
            belowValueUnit={props.data['MONTH_CAPACITY_UNIT']}
            percentage={props.data['POW_THISMONTH_EFFICIENCY']}
            chartColor={'#6894DD'}
          />
        </li>
        <li>
          <DescriptionBox title={'년간발전량'} />
          <PieChartBox
            aboveValue={props.data['POW_THISYEAR']}
            belowValue={props.data['YEAR_CAPACITY']}
            aboveValueUnit={props.data['POW_THISYEAR_UNIT']}
            belowValueUnit={props.data['YEAR_CAPACITY_UNIT']}
            percentage={props.data['POW_THISYEAR_EFFICIENCY']}
            chartColor={'#67B7DC'}
          />
        </li>
        <li>
          <DescriptionBox title={'누적발전량'} comments={['Co2절감량', `${props.data['CO2']}Ton`]} />
          <PieChartBox
            aboveValue={props.data['POW_TOTAL']}
            belowValue={props.data['YEAR_CAPACITY'] * 20}
            aboveValueUnit={props.data['POW_TOTAL_UNIT']}
            belowValueUnit={props.data['YEAR_CAPACITY_UNIT']}
            percentage={(100 / (props.data['YEAR_CAPACITY'] * 20)) * props.data['POW_TOTAL']}
            chartColor={'#FFCB06'}
          />
        </li>
      </ul>
    )
  },
  (prev, next) => {
    return prev.data['POW_TOTAL'] === next.data['POW_TOTAL'] && prev.data['POW_AC_P'] === next.data['POW_AC_P']
  },
)
