import {memo} from 'react'

export default memo(
  function MeasurementInformation(props) {
    return (
      <div className="con">
        <div className="solar_b">
          <span className="n01">{props.data['COLLECTOR_IN_TEMP']}℃</span>
          <span className="n02">{props.data['COLLECTOR_OUT_TEMP']}℃</span>
          <span className="n03">{props.data['TOP_HEAT_ACCUMULATOR_TEMP']}℃</span>
          <span className="n04">{props.data['HOT_PIPE_TEMP']}℃</span>
          <span className="n05">{props.data['BOTTOM_HEAT_ACCUMULATOR_TEMP']}℃</span>
          <span className="n06">{props.data['COLD_PIPE_TEMP']}℃</span>
          <div>
            <img src="/images/gate_image.png" alt="" />
          </div>
        </div>
        <ul>
          <li>
            <p className="sbj">측정 시간</p>
            <p className="num">
              <b>{props.data['HEATING_DATETIME']}</b>
            </p>
          </li>
          <li>
            <p className="sbj">집열기 입구온도(1T)</p>
            <p className="num">
              <b>{props.data['COLLECTOR_IN_TEMP']}</b>℃
            </p>
          </li>
          <li>
            <p className="sbj">집열기 출구온도(2T)</p>
            <p className="num">
              <b>{props.data['COLLECTOR_OUT_TEMP']}</b>℃
            </p>
          </li>
          <li>
            <p className="sbj">축열조 상부온도(3T)</p>
            <p className="num">
              <b>{props.data['TOP_HEAT_ACCUMULATOR_TEMP']}</b>℃
            </p>
          </li>
          <li>
            <p className="sbj">축열조 하부온도(4T)</p>
            <p className="num">
              <b>{props.data['BOTTOM_HEAT_ACCUMULATOR_TEMP']}</b>℃
            </p>
          </li>
          <li>
            <p className="sbj">급수온도(5T)</p>
            <p className="num">
              <b>{props.data['COLD_PIPE_TEMP']}</b>℃
            </p>
          </li>
          <li>
            <p className="sbj">급탕온도(6T)</p>
            <p className="num">
              <b>{props.data['HOT_PIPE_TEMP']}</b>℃
            </p>
          </li>
        </ul>
      </div>
    )
  },
  (prev, next) => {
    return (
      prev.data['SITE_ID'] === next.data['SITE_ID'] && prev.data['HEATING_DATETIME'] === next.data['HEATING_DATETIME']
    )
  },
)
