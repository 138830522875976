import {useState} from 'react'

export default function Table(props) {
  const [selectedRowNo, setSelectedRowNo] = useState(false)

  return (
    <table className="tbl_nor">
      <colgroup>
        {props.columns.map((column, index) => {
          return <col key={index} style={{width: column.width || 100 / props.columns.length}}></col>
        })}
      </colgroup>
      <thead>
        <tr>
          {props.columns.map((column, index) => {
            return <th key={index}>{column.label || column.columnName}</th>
          })}
        </tr>
      </thead>
      <tbody>
        {props.rows.map((row, index) => {
          return (
            <tr
              key={index}
              className={selectedRowNo === index ? 'active' : ''}
              onClick={() => {
                setSelectedRowNo(index)
                if (props.onClickTargetRow && typeof props.onClickTargetRow === 'function') {
                  props.onClickTargetRow(row)
                }
              }}
            >
              {props.columns.map((column, index) => {
                return <td key={index}> {row[column.columnName]} </td>
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
