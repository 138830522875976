import {useState} from 'react'
import {Outlet} from 'react-router-dom'

import Header from './Header'
import Nav from './Nav'

export default function MainLayout() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const [mobileMenuClassName, setMobileMenuClassName] = useState('')

  return (
    <div>
      <div id="wrap">
        <Header
          isMobileMenuOpen={isMobileMenuOpen}
          setIsMobileMenuOpen={setIsMobileMenuOpen}
          setMobileMenuClassName={setMobileMenuClassName}
        />
        <div id="contents">
          <Nav
            mobileMenuClassName={mobileMenuClassName}
            setIsMobileMenuOpen={setIsMobileMenuOpen}
            setMobileMenuClassName={setMobileMenuClassName}
          />
          <Outlet />
        </div>
      </div>
    </div>
  )
}
